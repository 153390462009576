import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import { Facebook, Twitter, WhatsApp } from "@material-ui/icons";

import logo_tagline from "src/images/logo-tagline.svg";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      display: "inline-flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      alignItems: "center",
      backgroundColor: theme.palette["dark_blue"].default,
      color: "white",
      textAlign: "left",
      padding: "2em",
      "& .MuiGrid-root": {
        justifyContent: "center",
        alignItems: "center",
      },
      "& .MuiGrid-item": {
        minHeight: "7em",
      },
      "& a": {
        color: "inherit",
        textDecoration: "inherit",
        fontSize: "inherit",
      },
      "& .col": {
        whiteSpace: "nowrap",
        [theme.breakpoints.down("sm")]: {
          textAlign: "center",
        },
      },
      "& .logo_social": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          marginBottom: "2em",
        },
      },
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LogoAndSocialMedia />
      <LearnLinks />
      <ContactLinks />
    </div>
  );
}

const LogoAndSocialMedia = () => (
  <div
    className="col logo_social"
    style={{ textAlign: "center", display: "block" }}
  >
    <a href="/">
      <img
        style={{ maxWidth: 250, padding: "1em" }}
        src={logo_tagline}
        alt="Fieldscope - Let's Do Good Better."
      />
    </a>
    <br />
    <a
      style={{ margin: "1em" }}
      href="https://www.facebook.com/fieldscope.in"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Facebook fontSize="large" />
    </a>
    <a
      style={{ margin: "1em" }}
      href="https://twitter.com/fieldscope_in"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Twitter fontSize="large" />
    </a>

    <a
      style={{ margin: "1em" }}
      href="https://wa.me/919769496818"
      rel="noopener noreferrer"
      target="_blank"
    >
      <WhatsApp fontSize="large" />
    </a>
  </div>
);

const LearnLinks = () => (
  <div className="col">
    <Typography variant="h6">Learn</Typography>
    <Typography variant="body1">
      <a href="/about/">About Us</a>
    </Typography>
    <Typography variant="body1">
      <a
        href="https://medium.com/fieldscope-blog"
        rel="noopener noreferrer"
        target="_blank"
      >
        Blog
      </a>
    </Typography>
    <Typography variant="body1">
      <a href="/faq/">FAQ</a>
    </Typography>
  </div>
);

const ContactLinks = () => (
  <div className="col">
    <Typography variant="h6">Contact Us</Typography>
    <Typography variant="body1">
      <a href="/signup/">Hire Talent</a>
    </Typography>
    <Typography variant="body1">
      <a href="/signup/provider/">Become a Provider</a>
    </Typography>
    <Typography variant="body1">
      <a href="mailto:support@fieldscope.in">support@fieldscope.in</a>
    </Typography>
    <Typography variant="body1">
      <a href="tel:+91-9769496818">+91-9769496818</a>
    </Typography>
  </div>
);
